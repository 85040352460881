<template>
    <table class="w-full border">
        <thead>
            <tr class="bg-gray-600 text-white children:py-2 children:px-2 ltr:text-left rtl:text-right">
                <th v-for="(column, columnIndex) in columns" :id="column.labelObject?.id" :key="column.field"
                    :style="column.labelObject?.style"
                    :class="[
                        column.labelObject?.class, column.sort ? 'cursor-pointer' : '',
                    ]"
                    @click="() => sortByColumn(column)">
                    <div class="flex gap-2 items-center relative">
                        <icon-btn v-if="column.draggable" icon="mdi:drag"
                                  class="opacity-50 hover:opacity-100 transition duration-300 cursor-move text-xl btn-drag"
                                  @mousedown="(event: Event) => $emit('drag:mousedown', {event, column, columnIndex})"
                                  @mouseup="(event: Event) => $emit('drag:mouseup', {event, column})"/>

                        <template v-if="$slots[`label:`+column.field]">
                            <slot :name="`label:`+column.field" :column="column" :column-index="columnIndex"/>
                        </template>
                        <template v-else>
                            {{ column.label }}
                        </template>

                        <button v-if="column.sort" class="sort-btn" :class="{active:currentSorting === column.field}">
                            <icon v-show="sortingDirection === 'asc'" icon="mdi:sort-ascending"/>
                            <icon v-show="sortingDirection === 'desc'" icon="mdi:sort-descending"/>
                        </button>
                    </div>
                </th>
            </tr>
        </thead>
        <tbody>
            <template v-if="!loading && data.length > 0">
                <tr v-for="(item, rowIndex) in data" :key="rowIndex" class="border-b-2">
                    <table-cell v-for="column in columns" :key="column.field" :index="rowIndex"
                                :column="column" :row="item" :style="{'max-width':column.maxWidth ?? 'auto'}">
                        <template v-for="(_, slot) in $slots" #[slot]="scope">
                            <slot :name="slot" v-bind="scope || {}" />
                        </template>
                    </table-cell>
                </tr>
            </template>

            <tr v-else-if="loading" class="text-center">
                <td :colspan="columns.length" class="py-2">
                    {{ t('common:loading') }} ...
                </td>
            </tr>

            <tr v-else class="text-center">
                <td :colspan="columns.length" class="py-2">
                    {{ t('common:no_data') }}
                </td>
            </tr>
        </tbody>
    </table>
</template>

<script setup lang="ts">

import t from "@lang/t";
import TableCell from "@components/tables/sub-components/TableCell.vue";
import type {Column} from "@components/tables/PrimaryTableTypes";
import {Icon} from "@iconify/vue";
import {ref} from "vue";
import {UrlHelper} from "@js/helpers/UrlHelper";

defineProps<{
    columns: Column[],
    data: Record<string, any>[]
    loading: boolean
}>()

const emits = defineEmits(['drag:mouseup', 'drag:mousedown', 'sort'])

let sortingDirection = ref<'asc' | 'desc'>('asc')
let urlHelper = new UrlHelper()
// todo we need to make get return array too
let currentSorting = ref<string|undefined>(urlHelper.get('order') ?? undefined)

function sortByColumn(column: Column)
{
    if(!column.sort) return;

    if(currentSorting.value === column.field)
    {
        sortingDirection.value = sortingDirection.value === 'asc' ? 'desc' : 'asc';
    }

    currentSorting.value = column.field;

    emits('sort', {column, direction: sortingDirection.value})
}
</script>

<style scoped lang="scss">
th {
    .sort-btn {
        visibility: hidden; opacity: 0; top: 100%; margin-top: -1rem; transition: all 300ms;
    }

    &:hover .sort-btn, .sort-btn.active {
        visibility: visible; opacity: 1; margin-top: 0;
    }
}
</style>
