export default {
    name: 'arabicFirst',

    lookup() {
        let found = undefined;

        if (typeof navigator !== 'undefined') {
            if (navigator.languages) { // chrome only; not an array, so can't use .push.apply instead of iterating
                found = navigator.languages.find(language => language === 'ar')
            }
        }

        return found;
    }
};
