import ar from '@lang/shared/ar.json'

export default {
    ...ar,
    'alaaqat': 'علاقات',
    'welcome': 'أهلاً {{name}}',
    "general": "عام",
    "notifications": "الإشعارات",
    "notification": "الإشعار",
    "security": "الأمان",
    "reports": "التقارير",

    "accounts": "الحسابات",
    "invoices": 'الفواتير',
    "undefined": "مجهول",
    "users": 'المستخدمين',
    "teams": 'الأفرقة',
    "permissions": 'الصلاحيات',

    'integrations': 'التكاملات',
    'integrate': 'كامل',
    'API': 'واجهة التطبيق البرمجية',

    'platforms': 'القنوات',
    'platform': 'القناة',
    'conversations': 'المحادثات',

    'properties': 'الخصائص',
    'contacts': 'العملاء',

    'personal_picture': 'الصورة الشخصية',
    'name': 'الإسم',
    'firstname': 'الإسم الأول',
    'lastname': 'الإسم الأخير',

    'email': 'البريد الإلكتروني',
    'password': 'كلمة المرور',
    'passwordConfirmation': 'تأكيد كلمة المرور',
    'OR': 'أو',
    '?' : '؟',

    'save': 'حفظ',
    'apply': 'تطبيق',
    'cancel': 'إلغاء',

    'processing': 'معالجة',
    'bundle': 'الحزمة',
    'bundles': 'الحزم',

    'action': 'إجراء',
    'actions': 'الإجراءات',
    'create': 'أنشئ',
    'creation': 'الإنشاء',
    'no_data': 'لا يوجد بيانات',
    'delete': 'حذف',
    'add': 'إضافة',
    'chatbot': 'مُحادث ذكي',

    'loading': 'جارِ التحميل',
    'IntegrationInProgress': 'جارِ التكامل',
    'activate': 'تفعيل',
    'deactivate': 'تعطيل',
    'inactive': 'غير مفعلة',
    'active': 'مفعل',
    'confirm': 'أكِّد',
    'areYouSure': 'هل أنت متأكد؟',
    'industry': 'المجال',
    'next': 'التالي',
    'resend': 'أعد الإرسال',

    'id': 'المُعرِف',
    'search': 'إبحث',
    'submit': 'أرسل',
    'signOut': 'تسجيل الخروج',
    'reset' : 'إعادة تعيين',
    'set' : 'تعيين',
    'somethingWentWrong': 'حدث خطأ ما',
    'addedBy': 'مُضاف من قبل',
    'phone': 'الهاتف',
    'created_at': 'تاريخ الإنشاء',
    'created_by': 'أُنشئ بواسطة',
    'avatar': 'الصورة الرمزية',
    'clickHere': 'إضغط هنا',
    'selected': 'مُختار',
    "privacy_policy": "سياسة الخصوصية",
    "google_maps": "خرائط جوجل",
    'group': 'المجموعة',
    'groups': 'المجموعات',
    'type': 'النوع',
    'tag_create_placeholder': 'اضغط على مفتاح الإدخال لإنشاء خيار جديد',
    'tag_placeholder': 'إختر من القائمة أو أنشِئ خيار جديد عن طريق الكتابة في هذا الحقل',
    'desc': 'الوصف',
    'system': 'النظام',
    'tableTail': 'يُعرض {{limit}} من أصل {{total}}',
    'menu' : 'القائمة',
    'details': 'التفاصيل',
    'image': 'صورة',
    'export': 'تصدير',
    'import': 'استيراد',
    'exchange': 'تبادل',
    'filter': 'تصفية',
    'view': 'العرض',
    'per_page': 'لكل صفحة',
    'all': 'الجميع',
    'activities': 'الأنشطة',
    'by': 'من قبل',
    'operation': 'العملية',
    'value': 'القيمة',
    'and': 'و',
    'or': 'أو',
    'description':'الوصف',
    'users_count':'عدد المستخدمين',
    'new_users':'المستخدمين الجدد',
    'status':"الحالة",
    'added_at':'تاريخ الانضمام',
    'continue': 'المتابعة',
    'title':'العنوان',
    '_id':'المُعرف',
    'notes':'ملاحظات',
    'contact_details':'تفاصيل العميل',
    'contact_activities':'نشاطات العميل',
    'account_locked':'لقد تم تعطيل وصولك إلى هذا الحساب من قبل مسؤول الحساب.',
    'updated_at':'تاريخ التحديث',
    'uploaded_files': 'الملفات المحملة',
    'the_format': 'الصيغة',
    'code': 'شيفرة',
    'account':'الحساب',
    'deleted': 'محذوف',
    'you': 'أنت',
    'language': 'اللغة',
    'soon': 'قريباً',
    'header': 'الترويسة',
    'body': 'المحتوى',
    'footer': 'التذيلة',
    'buttons': 'الازرار',
    'button': 'الزر',
    'url': 'رابط',
    "phone_input_placeholder": 'أدخل رقم الهاتف',
    "copy": 'نسخ',
    'content_copied': 'نُسخ النص',
    'selected_number':'{{number}} مُختار',
    'optional': 'إختياري',
    'use': 'إستخدم',
    'send': 'أرسل',
    'sync': 'مزامنة',
    'previous':'السابق',
    'update':'تحديث',
    'shared': 'مُشارَك',
    'attachment':'المرفق',
    'text':'النص',
    'plan':'الخطة',
    'free_plan':'الخطة المجانية',
    'free':'مجانية',
    'starter':'بدائية',
    'starter_plan':'الخطة البدائية',
    'business':'أعمال',
    'business_plan':'خطة الأعمال',
    'enterprise_plan':'خطة المؤسسات',
    'upgrade_plan_description':'لقد وصلت إلى الحد الأقصى المسموح به. يرجى اختيار أحد خطط الاشتراك المدفوعة للمتابعة.',
    "upgrade_to_premium":"الترقية إلى العضوية المميزة",
    "messages_per_month":'{{messages_per_month}} رسالة مرسلة شهريًا',
    "number_of_users_in_account": "عدد المستخدمين في الحساب: {{ users }}",
    'pre_defined_responses':'الردود المسبقة: {{ pre_defined_responses }}',
    'available_platforms': 'عدد القنوات المتاحة لكل قناة: فيسبوك ({{ facebook }})، واتساب ({{ whatsapp }})، وإنستجرام ({{ instagram }})',
    'contacts_export':'تصدير العملاء',
    'broadcast': 'إرسال النشر إلى جهات الاتصال التي تفاعلت سابقًا',
    'whatsapp_templates':'قوالب الواتساب: {{ whatsapp_templates }}',
    'free_plan_description':'خطة متاحة للجميع مجاناً! حتى تتمكن من الدخول للسوق بدون اي تكلفة.',
    'starter_plan_description':'الميزات والوظائف الأساسية بتكلفة منخفضة نسبيًا وبأسعار معقولة للشركات الناشئة.',
    'business_plan_description':'الحل المثالي الذي يقدم مجموعة شاملة من الميزات والفوائد من الدرجة الأولى للشركات التي تقدر عملائها.',
    'enterprise_plan_description':'عروض على أعلى مستوى ومصممة خصيصًا لمساعدة المؤسسات الكبيرة على التوسع بثقة',
    'start_free_plan':'إبدأ الخطة المجانية',
    'start_starter_plan':'إبدأ الخطة البدائية',
    'start_business_plan':'إبدأ خطة الأعمال',
    'contact_us':'تواصل معنا',

    // whatsapp template category
    'category':'الفئة',
    'automatic':'تلقائي',
    'AUTHENTICATION':'مصادقة',
    'MARKETING':'تسويقي',
    'UTILITY':'خدماتي',
    'deals':'الصفقات',
    'stage':'المرحلة',

    'billing': 'الفواتير',
    'total': 'المجموع',
    'tax': 'الضريبة',
    'close': 'أغلق',

    'subtotal': 'المجموع الفرعي',
    'discount': 'الخصم',
    'credit': 'رصيد مستخدم',
    'credit_to_balance': 'رصيد محجوز',
    'grand_total': 'المجموع الكلي',
    'subscription_type':'نوع الأشتراك',
    'yearly':'سنوي',
    'monthly':'شهري',

    "documentation": "كتيب التعليمات",
    'sync_messages':"مزامنة الرسائل",
    'message': 'الرسالة',

    'from': 'من',
    'to': 'إلى',
    'edited':'تم تعديل',

    'support_tickets_system' : 'نظام تذاكر الدعم',
    'attachments' : 'المرفقات',
    'tickets' :'التذاكر',
    'ad_title':'عنوان الإعلان',
    'product_id':'معرف المنتج',
    'post_id':'معرف المنشور',
    'business_bout':'عن العمل',
    'address':'العنوان',
    'website_links':'روابط المواقع',
    'picture': 'الصورة',

    'read_all_messages':'قراءة جميع الرسائل',
    'copy_and_close':'نسخ وإغلاق',
    'page_link':'رابط الصفحة',

    'unblock_contact_from_conversions':'إلغاء حظر العميل من المحادثات',
    'block_contact_from_conversions':'حظر العميل من المحادثات',

    // 404 page
    'go_to_home_page':'الذهاب إلى الصفحة الرئيسية',
    'sorry_we_could_not_find_this_page':'عذرًا، لم نتمكن من العثور على هذه الصفحة.',
    // But do not worry, you can find plenty of other things on our home page
    'but_do_not_worry_you_can_find_plenty_of_other_things_on_our_home_page':
    'ولكن لا تقلق، يمكنك العثور على العديد من الأشياء الأخرى على صفحتنا الرئيسية.',

    'support':'الدعم',
    'open': 'فتح',
    'true': 'صائب',
    'false': 'خاطئ',
    'merge':'دمج',
    'contact':'العميل',
    'element':'العنصر',
    'add_new_item': 'إضافة عنصر جديد',
    'template': 'القالب',
    'content': 'المحتوى',
    'misc': 'متنوع',
}
