// 1. Define route components.
// These can be imported from other files
import {createRouter, createWebHistory, type RouteRecordRaw} from "vue-router";
import {useLoadingStore} from "@js/stores/Loading";
import accountChildren from "@js/routes/accountChildren";
import {auth, doesNotHaveAccount, guest, verified} from "@js/routes/RouteGuards";
import {useLayoutStore} from "@js/stores/Layout";

// Routers to test when making changes to default route
// /
// /{account}/conversations/inbox
// /0/conversations/inbox
// /af234 -> to test 404
// /login -> after authentication

// 2. Define some routes
// Each route should map to a component.
// We'll talk about nested routes later.
const routes: Readonly<RouteRecordRaw[]> = [
    {
        name: 'register-wizard',
        path: '/register-wizard',
        beforeEnter: [auth, doesNotHaveAccount],
        component: () => import('@pages/auth/register-wizard.vue')
    },
    {
        path: "/",
        beforeEnter: auth,
        children: [
            {
                path: "/",
                name: "redirector",
                component: () => import('@pages/Redirector.vue')
            },
            {
                path: "",
                component: () => import("@js/layouts/AppLayout.vue"),
                children: [
                    {
                        path: 'accounts',
                        children: [
                            {
                                path: '',
                                name: 'accounts',
                                component: () => import('@pages/accounts/Index.vue')
                            },
                        ]
                    },
                    {
                        path: ':account(\\d+)',
                        beforeEnter: [verified],
                        component: () => import('@js/layouts/Account.vue'),
                        children: accountChildren,
                    },
                ]
            }
        ]
    },
    {
        path: '/',
        beforeEnter: guest,
        children: [
            {
                name: 'login',
                path: 'login',
                component: () => import('@pages/auth/Login.vue')
            },
            {
                name: 'register',
                path: 'register',
                component: () => import('@pages/auth/Register.vue')
            },
            {
                name: 'forget-password',
                path: 'forget-password',
                component: () => import('@pages/auth/ForgetPassword.vue')
            },
            {
                name: 'reset-password',
                path: '/reset-password/:token',
                component: () => import('@pages/auth/ResetPassword.vue')
            },
            {
                path:'two-factor-authentication',
                name: "two-factor-authentication",
                component: () => import('@pages/auth/TwoFactorAuthentication.vue')
            },

        ]
    },
    {
        name: 'user-invitation',
        path: '/users/:user/invitation',
        component: ()=> import('@pages/auth/UserInvitation.vue')
    },
    {
        path: '/404',
        name: '404',
        component: () => import('@pages/404.vue')
    },
    {
        path: '/logout',
        name: 'logout',
        component: () => import('@components/LoadingScreen.vue')
    },
    {
        path: '/:pathMatch(.*)*',
        component: () => import('@pages/404.vue')
    }
];

const router = createRouter({
    // 4. Provide the history implementation to use. We are using the hash history for simplicity here.
    history: createWebHistory(),
    routes, // short for `routes: routes`
});

// first to check
router.beforeEach(() => {
    const loading = useLoadingStore();
    loading.isActive = true
    useLayoutStore().pageClass = ''
})

// adjust full-minus-header height using JS
router.afterEach((to) => {
    const loading = useLoadingStore();
    loading.isActive = false
    if(typeof to.name === 'string') {
        useLayoutStore().pageClass = to.name?.replace('.', '_')
    }
})

// 3. Create the router instance and pass the `routes` option
// You can pass in additional options here, but let's
// keep it simple for now.
export default router;
