import api from "@js/APIs/api";

class TelescopeAPIs
{
    saveError(data: {}){
        //console.info(data)
        return api.post('/telescope/error-exception',data)
    }
}

export default new TelescopeAPIs()
