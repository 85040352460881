import router from "@js/routes/router";
import type {RouteLocation} from "vue-router";
import type {ID} from "@js/APIs/api";

class UrlResolver {

    getUrlAccountId(route: RouteLocation|null = null): ID | null
    {
        route = route ?? router.currentRoute.value

        if(route && route.params['account'])
        {
            return route.params['account'] as string
        }

        return null
    }
}

export default new UrlResolver()
