export default {
    'UNDEFINED': 'غير محدد',
    'OTHER': 'آخر',
    'AUTO': 'السيارات',
    'BEAUTY': 'الجمال',
    'APPAREL': 'الملابس',
    'EDU': 'التعليم',
    'ENTERTAIN': 'الترفيه',
    'EVENT_PLAN': 'تخطيط الفعاليات',
    'FINANCE': 'المالية',
    'GROCERY': 'البقالة', // GROCERY STORE
    'GOVT': 'الحكومة',
    'HOTEL': 'الفنادق',
    'HEALTH': 'الصحة',
    'NONPROFIT': 'المنظمات غير الربحية',
    'PROF_SERVICES': 'الخدمات المهنية',
    'RETAIL': 'البيع بالتجزئة',
    'TRAVEL': 'السفر',
    'RESTAURANT': 'المطاعم',
    'NOT_A_BIZ': 'نشاط غير تجاري',
    'ALCOHOL': 'الكحول',
    'ONLINE_GAMBLING': 'المقامرة عبر الإنترنت',
    'PHYSICAL_GAMBLING': 'المقامرة',
    'OTC_DRUGS': 'الأدوية المتاحة دون وصفة طبية',
}
