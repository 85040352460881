export default {
    'operationSuccess': 'Operation completed successfully',
    'tooManyAttempts': 'Too many attempts. Please wait and try again later.',
    'cantBeDisplayed': 'This element can\'t be displayed.',
    'cantBeDeleted':'The item cannot be deleted. Make sure the item is not in use.',
    'page_expired' : 'Page Expired',
    'emailSentSuccessfully': 'The email was sent successfully',
    'unauthorized':'This action is unauthorized',
    'error_on_the_previous_pages':'There might be an error on the previous pages.,',
    "please_login_for_account_invitation": "Please log in to the account to which the invitation was sent",
    'update_template_limit':'Alert, A template can be edited once per day, up to 10 times per month when status is Approved. However can edit unlimited number of times when status is Rejected or Paused.',
    'can_edit_template':'A template can only be edited when it is in a status of Approved, Rejected, or Paused.',
    'invoice_not_found': "This invoice can't be downloaded, please contact support to download it.",
    'request_entity_too_large':'Request Entity Too Large. Maximum size: 50 MB.',
    'support_success':'we have received your message, and we will reply back soon',
    'browser_permission_issue': "The operation cannot be done, please check browser permissions.",

}
