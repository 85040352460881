export default {
    'login_noun' : 'تسجيل الدخول',
    'login' : 'دخول',
    'create_new_account' : 'إنشاء حساب جديد',
    'forgot_your_password' : 'نسيت كلمة المرور؟',
    'signup' : 'تسجيل',
    'new_user' : 'مستخدم جديد',
    'already_has_an_account' : 'لديك حساب بالفعل ؟',
    'dont_have_an_account' : "ليس لديك حساب؟",
    'remember_me' : "تذكرني",
    'wrongCredentials': 'البريد أو كلمة المرور غير صحيحة',
    'whatIsYourIndustry' : 'ما المجال الذي تعمل به؟',
    'whatDoYouWantToCallYourAccount' : 'ماذا تريد أن تسمي حسابك؟',
    'pleaseVerifyYourEmail' : 'الرجاء النقر على الرابط الذي تم إرساله للتو إلى صندوق البريد الإلكتروني الخاص بك للتحقق من بريدك الإلكتروني والمتابعة إلى التطبيق.',
    'emailSentSuccessfully': 'تم إرسال البريد الإلكتروني بنجاح ، يرجى التحقق من صندوق البريد الإلكتروني الخاص بك',
    'tooManyVerificationEmails': 'يرجى الانتظار لمدة 5 دقائق على الأقل قبل محاولة إرسال رسالة التحقق مرة أخرى',
    'getBackToLoginPage': 'الرجوع لصفحة التسجيل',
    'forgetPasswordEmailSet': 'لقد أرسلنا إليك بريدًا إلكترونيًا يحتوي على رابط لإعادة تعيين كلمة المرور الخاصة بك ؛ يرجى التحقق من البريد الوارد الخاص بك.',
    "tooManyResetPasswordEmails_one": 'يرجى الانتظار لمدة دقيقة على الأقل قبل محاولة إرسال بريد إلكتروني آخر لإعادة تعيين كلمة المرور.',
    "tooManyResetPasswordEmails_two": 'يرجى الانتظار لمدة دقيقتان على الأقل قبل محاولة إرسال بريد إلكتروني آخر لإعادة تعيين كلمة المرور.',
    "tooManyResetPasswordEmails_few": 'يرجى الانتظار لمدة {{count}} دقائق على الأقل قبل محاولة إرسال بريد إلكتروني آخر لإعادة تعيين كلمة المرور.',
    "resetPassword": 'إعادة تعيين كلمة المرور',
    "resetPasswordSucceed": 'تم تغيير كلمة المرور الخاصة بك بنجاح ؛ يمكنك الآن تسجيل الدخول باستخدام كلمة المرور الجديدة الخاصة بك.',
    "signInUsingGoogle": 'تسجيل الدخول بإستخدام جوجل',
    "signUpUsingGoogle": 'تسجيل بإستخدام جوجل',
    'join':'الانضمام',
    'unauthorized':'هذا الإجراء غير مصرح به',
    'cant_resend':'لا يمكن إعادة إرسال إرسال دعوة أخرى بالبريد الإلكتروني إلى مستخدم نشط',
    'complete_login':'متابعة تسجيل الدخول',
    'use_recovery_code_instead':'إستخدم رمز الإسترداد',
    'recovery_code':'رمز الاسترداد',
    'authentication_code':'رمز المصادقة'

}
