export default {
    'newContact': 'جهة إتصال جديدة',
    'contact': 'جهة إتصال',
    'contact_details': 'معلومات جهة الإتصال',

    'all_stages': 'جميع المراحل',

    'activity_created': 'تم إنشاء الكَيان.',
    'activity_updated': "تم التحديث",

    'export_view':'عرض التصدير',
    'send_to':'إرسال و تصدير الملف الى {{email}}.',
    'type_file':'نوع الملف',
    'alert_properties_col':'تنبيه تصدير الاعمدة المحددة عددها ',
    'import_view':"عرض الاستيراد",
    'send_notification':'إرسال إشعار الى ',
    'upload_file':'تحميل الملف هنا',
    'choose_import':'طريقة استيراد جهة إتصال',
    'create_only':'إنشاء فقط',
    'update_create':'تحديث أو إنشاء',
    'headings':'العامود',
    'unique_keys':"اختر المفتاح الفريد",
    'mapping_guide':'دليل رسم الخرائط',
    'import_send_to':'سنقوم بإعلامك عند الانتهاء من عملية الاستيراد',
    'new_note':'ملاحظة جديدة',
    'drag_and_drop_import_file': 'ضع ملف هنا، أو انقر لتحديد واحد.',
    'allowed_extensions_are': 'الملفات المسموحة بها',
    'contacts_view':'عرض العملاء',
    'new_view':' إنشاء عرض جديدة',
    'update_view':'تعديل العرض',
    'private':'خاص',
    'public':'عام',
    'visibility':'حالة العرض',
    'is_default':'العرض الأفتراضي',
    'main_view':'العرض الرئيسي',
    'save_contact': 'حفظ جهة الإتصال',
    'add_property':'إضافة خاصية',
    'property_error_message': 'خطأ: {{propertyName}} موجودة بالفعل.',
    'updated_from': "تم التحديث من",
    'to':'إلى',
    'send_template_to':'أرسل قالب إلى {{phone}}',
    'send_broadcast_template_via_whatsapp':'إرسال قالب النشر عبر واتساب',

    'new_deal': 'صفقة جديدة',
    'deal_stage' : 'مرحلة الصفقة',
    'appointment_scheduled' : 'تم جدولة موعد',
    'qualified_to_buy' : 'مؤهَل للشراء',
    'presentation_scheduled' : 'تم جدولة عرض تقديمي',
    'decision_maker_bought_in' : 'صاحب القرار مهتم',
    'contract_sent' : 'تم إرسال العقد',
    'close_won' : 'إغلاق ناجح',
    'close_lost' : 'إغلاق فاشل',
    'contacts_deal':'عملاء الصفقة',
    'contacts_search':'إبحث عن العملاء',
    'amount':'المبلغ',
    'deal_name':'أسم الصفقة',
    'link_contacts':'ربط العملاء',
    'link_deals':'ربط الصفقات',
    'deals_contact':'صفقات العميل',
    'deals_search':'إبحث عن الصفقات',

    'new_ticket':'تذكرة جديدة',
    'contacts_ticket':'عملاء التذكرة',
    'deals_ticket':'صفقات التذكرة',
    'ticket_name':'أسم التذكرة',
    'all_categories':'جميع الفئات',

    'product_issue' : 'مشكلة المنتج',
    'billing_issue' : 'مشكلة الفواتير',
    'feature_request' : 'طلب ميزة',
    'general_inquiry' : 'استفسار عام',

    'tickets_deal': 'تذاكر الصفقة',
    'tickets_search': 'بحث عن التذاكر',
    'link_tickets': 'ربط التذاكر',
    'tickets_contact': 'تذاكر العميل',
    'source_manual': 'يدوي',
    'source_conversation': 'محادثة',
    'go_to_contact': 'إذهب لجهة الإتصال',

    'merge_contacts': 'دمج جهات الإتصال',
    'merge_contacts_tooltip': 'دمج جهات الإتصال الحالية مع جهة إتصال أخرى',
    'merge_contacts_description': 'سوف  يتم دمج جهة الإتصال الحالية مع جهة الإتصال التي يتم تحديدُها.' +
        ' سيتم حذف جهة الإتصال الحالية و نقل جميع البيانات المرتبطة بها. هل أنت متأكد من أنك تريد المتابعة؟',
    'select_contact_to_merge': 'اختر جهة الإتصال لدمجها',
    'channels': 'القنوات',

    'contacts_merged': 'تم دمج جهات الإتصال بنجاح',
}
