export default {
    'login_noun' : 'Login',
    'login' : 'login',
    'create_new_account' : 'create new account',
    'forgot_your_password' : 'forgot your password?',
    'signup' : 'Sign up',
    'new_user' : 'New User',
    'already_has_an_account' : 'Already has an account?',
    'dont_have_an_account' : "Don't have an account?",
    'remember_me' : "Remember me",
    'wrongCredentials': 'Incorrect email or password',
    'whatIsYourIndustry' : 'What industry are you in?',
    'whatDoYouWantToCallYourAccount' : 'What do you want to call your account?',
    'pleaseVerifyYourEmail' : 'Please click on the link just sent to your email inbox to verify your email and continue to the application.',
    'emailSentSuccessfully': 'The email was sent successfully, please check your email inbox',
    'tooManyVerificationEmails': 'Please wait at least 5 minutes before trying to send another verification email again',
    'getBackToLoginPage': 'Get back to the login page',
    'forgetPasswordEmailSet': 'We have sent you an email with a link to reset your password; please check your inbox.',
    "tooManyResetPasswordEmails_one": 'Please wait at least {{count}} minute before trying to send another reset password email.',
    "tooManyResetPasswordEmails_few": 'Please wait at least {{count}} minutes before trying to send another reset password email.',
    "tooManyResetPasswordEmails_other": 'Please wait at least {{count}} minutes before trying to send another reset password email.',
    "resetPassword": 'Reset Password',
    "resetPasswordSucceed": 'Your password was changed successfully; you can now log in using your new password.',
    "signInUsingGoogle": 'Sign in with Google',
    "signUpUsingGoogle": 'Sign up with Google',
    'join':'Join',
    'unauthorized':'This action is unauthorized',
    'cant_resend':'can\'t resend send another invitation email to active user',
    'complete_login':'Complete Login',
    'use_recovery_code_instead':'Use recovery code instead',
    'recovery_code':'Recovery Code',
    'authentication_code':'Authentication Code',
}
