<template>
    <Transition class="transition" :style="{
                    transitionDuration: duration + 's',
                    '--start': sign + start.toString(),
                    '--end': sign + end.toString(),
                    '--delay-out': delayOut + 's',
                    '--delay-in': delayIn + 's',
                    '--fade': fade ? 0 : 1
                }"
                style="transform-origin: center">
        <slot />
    </Transition>
</template>

<script setup lang="ts">
import {computed} from "vue";

const props = defineProps({
    dir: {
        type: String as () => 'out' | 'in',
        default: 'out'
    },
    duration: {
        type: Number,
        default: 0.5
    },
    start: {
        type: Number,
        default: 0.8
    },
    end: {
        type: Number,
        default: 1
    },
    delayIn: {
        type: Number,
        default: 0
    },
    delayOut: {
        type: Number,
        default: 0
    },
    fade: {
        type: Boolean,
        default: true
    }
})

let sign = computed(() => {
    return props.dir === 'out' ?  '' : '-'
})
</script>

<style scoped lang="scss">

.v-enter-active,
.v-leave-active {

}

.v-leave-active {
    transition-delay: var(--delay-out);
}

.v-enter-active {
    transition-delay: var(--delay-in);
}

.v-enter-to, .v-leave-from {
    transform: scale(var(--end));
}

.v-enter-from,
.v-leave-to {
    opacity: var(--fade);
    transform: scale(var(--start));
}
</style>
