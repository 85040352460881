export default {
    'property': 'Property',
    'properties': 'Properties',
    'properties_desc': 'Properties are utilized to gather and save data about your records, allowing you to process or import them at a later time.',
    'new_property': 'New Property',
    'group_name': 'Group Name',
    'name_team':'Name Team',

    'string': 'Single-line text',
    'text': 'Multi-line text',
    'number':'Number',
    'single_select': 'Single Select',
    'date': 'Date',
    'currency': 'Currency',
    'image': 'Image',
    'user': 'User',
    'team': 'Team',
    'location': 'Location',
    'media': 'Media',
    'video': 'video',
    'document': 'document',

    // operations
    'exists': 'Exists',
    'doesntExist': 'Doesn\'t Exist',
    'equal': 'Equal',
    'notEqual': 'Not equal',
    'in': 'In',
    'notIn': 'Not in',
    'greater': 'Greater',
    'greaterOrEqual': 'Greater or equal',
    'less': 'Less',
    'lessOrEqual': 'Less or equal',

    'selected_columns': 'Selected Columns',

    'global_property_group':'Global Property Group',
    'alert':'Alert',
    'internal_name_alert':'This unique identifier will be used when syncing with integration partners. Once created it can\'t be edited.',
    'update_property':'Update Property',
    'date_format':'Date Format',
    'time':'Time',
    'dateTime':'DateTime',
    'options':'Options',
    'option':'Option {{index}}',
    'option_language':'Option in language ',
    'add_new_option':'add new option',
    "change_language_for_input_name_option": "option's language",
    'phone_number': 'Phone Number'

}
