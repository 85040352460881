<template>
    <footer class="mt-2 flex flex-col">
        <hr class="mb-4" >
        <div>
            <slot />
        </div>
    </footer>
</template>

<script setup lang="ts">
</script>

<style scoped lang="scss"></style>
