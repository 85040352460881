export default {
    IncomingMessage: 'Incoming message',
    ObjectsExport:'objects export',
    ObjectsImport:'objects import',
    WhatsAppTemplateChangeStatus:'WhatsApp template change status',
    WhatsAppChangeTemplateCategory:'WhatsApp template change category',
    WhatsappBroadcastCompleted:'Whatsapp broadcast completed',
    'read_as_mark':'Read as mark',
    'un_read_mark':'Unread mark',
    'un_read_notifications':'Unread Notifications',
    'all_notifications':'All Notifications',
    'export_contacts_completed':'Export contacts have been completed.',
    'import_contacts_completed':'Import contacts have been completed.',
    'whatsapp_template_Approved':'whatsapp template Approved',
    'whatsapp_template_Rejected':'whatsapp template Rejected',
    'whatsapp_template_Pending' : 'Whatsapp Template Pending',
    'whatsapp_template_InAppeal':'Whatsapp Template InAppeal',

    'whatsapp_template_Paused':'Whatsapp Template Paused',
    'whatsapp_template_category_changed_to_utility' : 'whatsapp template category changed to utility',
    'whatsapp_template_category_changed_to_marketing' : 'whatsapp template category changed to marketing',
    'whatsapp_template_category_changed_to_authentication' : 'whatsapp template category changed to authentication',
}
