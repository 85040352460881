export default {
    startsWith: 'يبدأ بـ',
    contains: 'يحتوي على',
    notContains: 'لا يحتوي على',
    endsWith: 'ينتهي بـ',
    equals: 'يساوي',
    notEquals: 'لا يساوي',
    noFilter: 'بدون فلتر',
    lt: 'أقل من',
    lte: 'أقل من أو يساوي',
    gt: 'أكبر من',
    gte: 'أكبر من أو يساوي',
    dateIs: 'التاريخ هو',
    dateIsNot: 'التاريخ ليس',
    dateBefore: 'قبل التاريخ',
    dateAfter: 'بعد التاريخ',
    clear: 'مسح',
    apply: 'تطبيق',
    matchAll: 'تطابق الكل',
    matchAny: 'تطابق أي',
    addRule: 'إضافة قاعدة',
    removeRule: 'إزالة قاعدة',
    accept: 'نعم',
    reject: 'لا',
    choose: 'اختيار',
    upload: 'رفع',
    cancel: 'إلغاء',
    completed: 'مكتمل',
    pending: 'قيد الانتظار',
    dayNames: ['الأحد', 'الاثنين', 'الثلاثاء', 'الأربعاء', 'الخميس', 'الجمعة', 'السبت'],
    dayNamesShort: ['أحد', 'اثنين', 'ثلاثاء', 'أربعاء', 'خميس', 'جمعة', 'سبت'],
    dayNamesMin: ['ح', 'ن', 'ث', 'ر', 'خ', 'ج', 'س'],
    monthNames: ['يناير', 'فبراير', 'مارس', 'أبريل', 'مايو', 'يونيو', 'يوليو', 'أغسطس', 'سبتمبر', 'أكتوبر', 'نوفمبر', 'ديسمبر'],
    islamicMonths: ["مُحَرَّم", "صَفَر", "رَبِيع الأوَّل", "رَبِيع الثَّانِي", "جُمَادى الأوَّل", "جُمَادى الثَّانِيَة", "رَجَب", "شَعْبَان", "رَمَضَان", "شَوَّال", "ذُو الْقَعْدَة", "ذُو الْحِجَّة"],
    monthNamesShort: ['يناير', 'فبراير', 'مارس', 'أبريل', 'مايو', 'يونيو', 'يوليو', 'أغسطس', 'سبتمبر', 'أكتوبر', 'نوفمبر', 'ديسمبر'],
    gregory: 'ميلادي',
    hijiri: 'هجري',
    chooseYear: 'اختر السنة',
    chooseMonth: 'اختر الشهر',
    chooseDate: 'اختر التاريخ',
    prevDecade: 'العقد السابق',
    nextDecade: 'العقد التالي',
    prevYear: 'السنة السابقة',
    nextYear: 'السنة التالية',
    prevMonth: 'الشهر السابق',
    nextMonth: 'الشهر التالي',
    prevHour: 'الساعة السابقة',
    nextHour: 'الساعة التالية',
    prevMinute: 'الدقيقة السابقة',
    nextMinute: 'الدقيقة التالية',
    prevSecond: 'الثانية السابقة',
    nextSecond: 'الثانية التالية',
    am: 'ص',
    pm: 'م',
    today: 'اليوم',
    weekHeader: 'أسبوع',
    firstDayOfWeek: 0,
    dateFormat: 'mm/dd/yy',
    weak: 'ضعيف',
    medium: 'متوسط',
    strong: 'قوي',
    passwordPrompt: 'أدخل كلمة مرور',
    emptyFilterMessage: 'لم يتم العثور على نتائج',
    searchMessage: 'تتوفر {0} نتائج',
    selectionMessage: 'تم تحديد {0} عنصر',
    emptySelectionMessage: 'لا يوجد عنصر محدد',
    emptySearchMessage: 'لم يتم العثور على نتائج',
    emptyMessage: 'لا توجد خيارات متاحة',
    aria: {
        trueLabel: 'صحيح',
        falseLabel: 'خاطئ',
        nullLabel: 'لم يتم التحديد',
        star: 'نجمة واحدة',
        stars: '{star} نجوم',
        selectAll: 'تحديد جميع العناصر',
        unselectAll: 'إلغاء تحديد جميع العناصر',
        close: 'إغلاق',
        previous: 'السابق',
        next: 'التالي',
        navigation: 'التنقل',
        scrollTop: 'التمرير للأعلى',
        moveTop: 'نقل إلى الأعلى',
        moveUp: 'نقل لأعلى',
        moveDown: 'نقل لأسفل',
        moveBottom: 'نقل إلى الأسفل',
        moveToTarget: 'نقل إلى الهدف',
        moveToSource: 'نقل إلى المصدر',
        moveAllToTarget: 'نقل الكل إلى الهدف',
        moveAllToSource: 'نقل الكل إلى المصدر',
        pageLabel: 'الصفحة {page}',
        firstPageLabel: 'الصفحة الأولى',
        lastPageLabel: 'الصفحة الأخيرة',
        nextPageLabel: 'الصفحة التالية',
        prevPageLabel: 'الصفحة السابقة',
        rowsPerPageLabel: 'عدد الصفوف في الصفحة',
        jumpToPageDropdownLabel: 'الانتقال إلى صفحة',
        jumpToPageInputLabel: 'الانتقال إلى صفحة',
        selectRow: 'تحديد صف',
        unselectRow: 'إلغاء تحديد صف',
        expandRow: 'توسيع الصف',
        collapseRow: 'طي الصف',
        showFilterMenu: 'إظهار قائمة الفلتر',
        hideFilterMenu: 'إخفاء قائمة الفلتر',
        filterOperator: 'مشغل الفلتر',
        filterConstraint: 'قيد الفلتر',
        editRow: 'تحرير الصف',
        saveEdit: 'حفظ التعديل',
        cancelEdit: 'إلغاء التعديل',
        listView: 'عرض قائمة',
        gridView: 'عرض شبكة',
        slide: 'شريحة',
        slideNumber: 'الشريحة {slideNumber}',
        zoomImage: 'تكبير الصورة',
        zoomIn: 'تكبير',
        zoomOut: 'تصغير',
        rotateRight: 'تدوير لليمين',
        rotateLeft: 'تدوير لليسار'
    }
}
