import {defineStore} from "pinia";

export const useLayoutStore = defineStore('layout', {
    state: () => ({
        overlay: [] as Symbol[],
        pageClass: ''
    }),
    actions: {
        add(symbol: Symbol)
        {
            this.overlay.push(symbol)
            document.getElementsByTagName('body')[0].classList.add('overflow-hidden')
        },
        remove(symbol: Symbol)
        {
            this.overlay = this.overlay.filter(s => s != symbol)

            if(this.overlay.length === 0) {
                document.getElementsByTagName('body')[0].classList.remove('overflow-hidden')
            }
        }
    }
})
