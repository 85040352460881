import { defineStore } from 'pinia'

export type ValidationErrors = {
    errors: Record<string, string[]>
    message: string,
}

export const useValidationErrors = defineStore('validationErrors', {
    state: (): ValidationErrors => ({
        'errors': {},
        'message': '',
    }),
    actions: {
        clearError(fieldName: string): void {
            if (this.errors.hasOwnProperty(fieldName)) {
                delete this.errors[fieldName];
                this.message='';
            }
        },
        clearErrors(): void {
            this.$patch({
                errors: {},
                message: '',
            })
        },
    }

});
