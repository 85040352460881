<template>
    <button type="submit" :class="computedProcessing ? 'opacity-75 cursor-not-allowed': ''"
            :disabled="computedProcessing">
        <span v-if="computedProcessing">{{ t('common:processing') }}
            <Icon icon="mdi:loading" class="animate-spin inline"/>
        </span>
        <slot v-else/>
    </button>
</template>

<script setup lang="ts">
import type {Ref} from "vue";
import {computed, inject} from "vue";

const props = withDefaults(defineProps<{
    processing?: boolean
}>(), {
    processing: undefined
})

import t from '@lang/t'

const injectionProcessing = inject('processing', undefined) as Ref<boolean> | undefined

const computedProcessing = computed(() => {
    return props.processing ?? injectionProcessing?.value ?? false
})
</script>

<style scoped lang="scss"></style>
