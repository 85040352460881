import Echo from "laravel-echo";
import Pusher from "pusher-js";
import type {Message} from "@js/models/conversations/Messageable";
import type {Messageable} from "@js/models/conversations/Messageable";
import type {ContactDataType, ContactInterface} from "@js/models/contacts/Contact";
import type {PopupNotificationType} from "@js/PopupNotifications";

let pusher = Pusher
let echo = new Echo({
    broadcaster: 'pusher',
    key: import.meta.env.VITE_PUSHER_APP_KEY,
    cluster: import.meta.env.VITE_PUSHER_APP_CLUSTER,
    forceTLS: true
});

export type WebsocketAccountMessage = {
    message: Message,
    messageable: Messageable,
    contact: ContactInterface & {properties: ContactDataType}
    has_sound: boolean,
}

export type WebsocketAccountNotification = {
    popup?: PopupNotificationType
}

export {echo, pusher};
