<template>
    <td class="text-gray-600 py-2 px-2">
        <template v-if="$slots['cell:'+column.field]">
            <slot :name="'cell:'+column.field" :item="row" :row-index="index"/>
        </template>
        <template v-else-if="column.type==='date'&& defaultContent">
            {{ DateHelper.fromIso8601String(defaultContent as string).toDateTimeString() }}
        </template>
        <template v-else>
            {{ defaultContent }}
        </template>
    </td>
</template>

<script setup lang="ts">

import {objectT} from "@lang/t";
import type {Column} from "@components/tables/PrimaryTableTypes";
import {computed} from "vue";
import {get} from "lodash-es";
import {DateHelper} from "@js/helpers/DateHelper";

const props = defineProps<{
    index: number
    column: Column
    row: any
}>();

let defaultContent = computed(() => {
    let value = get(props.row, props.column.field)

    return props.column.translate && value
        ? (objectT(value))
        : value
})
</script>

<style scoped lang="scss"></style>
