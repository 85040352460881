export default {
    "Accounting": "Accounting",
    "AlternativeDispute": "Alternative Dispute Resolution",
    "AlternativeMedicine": "Alternative Medicine",
    "Animation": "Animation",
    "Apparel": "Apparel & Fashion",
    "Architecture": "Architecture & Planning",
    "Arts": "Arts & Crafts",
    "Automotive": "Automotive",
    "Aviation": "Aviation & Aerospace",
    "Banking": "Banking",
    "Biotechnology": "Biotechnology",
    "Broadcast": "Broadcast Media",
    "Building": "Building Materials",
    "Business": "Business Supplies & Equipment",
    "Capital": "Capital Markets",
    "Chemicals": "Chemicals",
    "Civic": "Civic & Social Organization",
    "Civil": "Civil Engineering",
    "Commercial": "Commercial Real Estate",
    "Computer": "Computer & Network Security",
    "ComputerGames": "Computer Games",
    "ComputerHardware": "Computer Hardware",
    "ComputerNetworking": "Computer Networking",
    "ComputerSoftware": "Computer Software",
    "Construction": "Construction",
    "Consumer": "Consumer Electronics",
    "ConsumerGoods": "Consumer Goods",
    "ConsumerServices": "Consumer Services",
    "Cosmetics": "Cosmetics",
    "Dairy": "Dairy",
    "Defense": "Defense & Space",
    "Design": "Design",
    "Education": "Education Management",
    "E": "E-learning",
    "Electrical": "Electrical & Electronic Manufacturing",
    "Entertainment": "Entertainment",
    "Environmental": "Environmental Services",
    "Events": "Events Services",
    "Executive": "Executive Office",
    "Farming": "Farming",
    "Financial": "Financial Services",
    "Fishery": "Fishery",
    "FoodProduction": "Food Production",
    "Fundraising": "Fundraising",
    "Furniture": "Furniture",
    "Gambling": "Gambling & Casinos",
    "Glass": "Glass, Ceramics & Concrete",
    "Government": "Government Administration",
    "GovernmentRelations": "Government Relations",
    "Graphic": "Graphic Design",
    "Health": "Health, Wellness & Fitness",
    "Higher": "Higher Education",
    "Hospital": "Hospital & Health Care",
    "Human": "Human Resources",
    "Import": "Import & Export",
    "Individual": "Individual & Family Services",
    "Industrial": "Industrial Automation",
    "Information": "Information Services",
    "InformationTechnology": "Information Technology & Services",
    "Insurance": "Insurance",
    "International": "International Affairs",
    "InternationalTrade": "International Trade & Development",
    "InvestmentBanking": "Investment Banking/Venture",
    "InvestmentManagement": "Investment Management",
    "Judiciary": "Judiciary",
    "Law": "Law Enforcement",
    "LawPractice": "Law Practice",
    "Legal": "Legal Services",
    "Legislative": "Legislative Office",
    "Libraries": "Libraries",
    "Logistics": "Logistics & Supply Chain",
    "Luxury": "Luxury Goods & Jewelry",
    "Machinery": "Machinery",
    "Management": "Management Consulting",
    "Maritime": "Maritime",
    "Marketing": "Marketing & Advertising",
    "Market": "Market Research",
    "Mechanical": "Mechanical or Industrial Engineering",
    "Media": "Media Production",
    "MedicalDevice": "Medical Device",
    "MedicalPractice": "Medical Practice",
    "Mental": "Mental Health Care",
    "Military": "Military",
    "Mining": "Mining & Metals",
    "Motion": "Motion Pictures & Film",
    "Museums": "Museums & Institutions",
    "Music": "Music",
    "Nanotechnology": "Nanotechnology",
    "Newspapers": "Newspapers",
    "Nonprofit": "Nonprofit Organization Management",
    "Oil": "Oil & Energy",
    "Online": "Online Publishing",
    "Outsourcing": "Outsourcing/Offshoring",
    "Package": "Package/Freight Delivery",
    "Packaging": "Packaging & Containers",
    "Paper": "Paper & Forest Products",
    "Performing": "Performing Arts",
    "Pharmaceuticals": "Pharmaceuticals",
    "Philanthropy": "Philanthropy",
    "Photography": "Photography",
    "Plastics": "Plastics",
    "Political": "Political Organization",
    "Primary": "Primary/Secondary",
    "Printing": "Printing",
    "Professional": "Professional Training",
    "Program": "Program Development",
    "PublicPolicy": "Public Policy",
    "PublicRelations": "Public Relations",
    "PublicSafety": "Public Safety",
    "Publishing": "Publishing",
    "Railroad": "Railroad Manufacture",
    "Ranching": "Ranching",
    "Real": "Real Estate",
    "Recreational": "Recreational",
    "Facilities": "Facilities & Services",
    "Religious": "Religious Institutions",
    "Renewables": "Renewables & Environment",
    "Research": "Research",
    "Restaurants": "Restaurants",
    "Retail": "Retail",
    "Security": "Security & Investigations",
    "Semiconductors": "Semiconductors",
    "Shipbuilding": "Shipbuilding",
    "Sporting": "Sporting Goods",
    "Sports": "Sports",
    "Staffing": "Staffing & Recruiting",
    "Supermarkets": "Supermarkets",
    "Telecommunications": "Telecommunications",
    "Textiles": "Textiles",
    "Think": "Think Tanks",
    "Tobacco": "Tobacco",
    "Translation": "Translation & Localization",
    "Transportation": "Transportation/Trucking/Railroad",
    "Utilities": "Utilities",
    "Venture": "Venture Capital",
    "Veterinary": "Veterinary",
    "Warehousing": "Warehousing",
    "Wholesale": "Wholesale",
    "Wine": "Wine & Spirits",
    "Writing": "Writing & Editing"
}
