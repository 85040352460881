import {useUser} from "@js/stores/User";
import urlResolver from "@js/routes/UrlResolver";
import type {RouteLocation} from "vue-router";
import type {RouteLocationNormalized, RouteLocationRaw} from "vue-router";

export async function verified()
{
    const userStore = useUser()

    if(!userStore.user?.email_verified_at) {
        return accountRoute()
    }
}

export async function doesNotHaveAccount()
{
    const userStore = useUser()

    if(userStore.user?.account) {
        return accountRoute()
    }
}

export async function account()
{
    const userStore = useUser()

    if(!userStore.user?.account) {
        return accountRoute()
    }
}

export async function auth(to: RouteLocation)
{
    const userStore = useUser()

    if(!userStore.user)
    {
        // try to fetch the user
        return userStore.fetchUser(urlResolver.getUrlAccountId(to))
            .then(function (user) {
                if(!user.account) {
                    return accountRoute()
                }
            }).catch(() => {
                // if couldn't fetch the user redirect him to the login page
                userStore.latest_accounts = []
                return { name: 'login', query: {to: to.fullPath} }
            })
    }
}

export async function checkTeamPermissions(to:RouteLocation){
    if(useUser().can(...to.meta.permissions as string[])) return ;

    return defaultRoute();
}
export function guest(to: RouteLocation)
{
    const userStore = useUser()

    if(!userStore.user)
    {
        // try to fetch the user
        return userStore.fetchUser(urlResolver.getUrlAccountId(to)).then(function () {
            return defaultRoute()
        }).catch(() => {
            return;
        })
    }

    return defaultRoute()
}

/**
 * You can consider that the user exist at this point, as the default route is guarded by fetchUser
 * @param to
 */
export function defaultRoute(to: RouteLocation|null = null): RouteLocationRaw {
    let userStore = useUser()

    let resolvedAccountId = urlResolver.getUrlAccountId(to);

    if(resolvedAccountId) {
        return {name: 'conversations.inbox'}
    }

    if(userStore.auth.account) {
        return {name: 'conversations.inbox', params: {'account': userStore.auth.account.id}}
    }

    return {name: 'accounts'}
}

export function accountRoute(to?: RouteLocationNormalized)
{
    let distention =  !to || to.name === 'accounts' ? '/' : to.fullPath
    return { name: 'accounts', query: {to: distention} }
}
