import type {WebsocketAccountNotification} from "./websocket";
import {echo} from "./websocket"
import {useUser} from "@js/stores/User";
import {TYPE, useToast} from "vue-toastification";
import NotificationWithTitle from "@components/NotificationWithTitle.vue";
import {NotificationsEnum} from "@js/types/generated";
import notificationSound from '@js/assets/sounds/notification.wav'

export type PopupNotificationType = {
    title?: string,
    type?: TYPE
    content: string,
    icon?: string,
    actions?:[
        {
            title: string,
            action: string
            icon?: string,
        }
    ]
}

/**
 * How to use: just add function with the name of the notification, it will be automatically registered
 */
class PopupNotifications {

    public accountNotificationsToListen = [
        // add account level notifications here
        NotificationsEnum[NotificationsEnum.IncomingMessage]
    ];

    public notificationsToSuppress: string[] = [];

    constructor() {
    }

    suppress(notification: string) {
        this.notificationsToSuppress.push(notification);
    }

    init()
    {
        this.accountNotifications();

        echo.private(this.getUserChannel())
            .listen('.popup', (notification: { popup: PopupNotificationType|undefined }) => {
                this.triggerNotification(notification.popup)
            })
    }

    reset()
    {
        echo.leave(this.getAccountChannel())
        echo.leave(this.getUserChannel())
        this.notificationsToSuppress = []

        this.init();
        return this;
    }

    getAccountChannel()
    {
        let user = useUser().auth
        if(!user.account) return '';

        return `account.${user.account.id}`;
    }

    getUserChannel()
    {
        let user = useUser().auth

        return `users.${user.id}`;
    }

    accountNotifications()
    {
        let user = useUser().auth
        if(!user.account) return;

        let channel = echo.private(this.getAccountChannel());

        this.accountNotificationsToListen.forEach(notification => {
            let preference = user.notification_preferences?.find((preference) => preference.name === notification);
            if(preference?.popup) {
                channel.listen('.' + notification, (e: WebsocketAccountNotification) => {
                    if(!this.notificationsToSuppress.includes(notification)) {
                        this.triggerNotification(e.popup)
                    }
                })
            }
        })
    }

    playAudio()
    {
        let audio = new Audio(notificationSound);
        audio.play();
    }

    triggerNotification(notification: PopupNotificationType|undefined)
    {
        if (notification){
            this.playAudio();
            let toast = useToast();
            toast({
                component: NotificationWithTitle,
                props: {
                    title: notification.title,
                    content: notification.content,
                    icon: notification.icon,
                    actions:notification.actions
                }
            }, {
                icon: false,
                type: notification.type ?? TYPE.INFO,
                toastClassName:'items-start',
                closeButtonClassName:'mt-1 '
            });
        }
    }
}

export default new PopupNotifications();
