export default {
    'search' : 'إبحث',
    'skinTone' : 'لون البشرة',
    "smileys_people": "الابتسامات والأشخاص",
    "animals_nature": "الحيوانات والطبيعة",
    "food_drink": "الطعام والشراب",
    "activities": "الأنشطة",
    "travel_places": "أماكن السفر",
    "objects": "الأشياء",
    "symbols": "الرموز",
    "flags": "الأعلام",
    "recent": "المستخدمة حديثاً"
}
