export default {
    'UNDEFINED': 'Undefined',
    'OTHER': 'Other',
    'AUTO': 'Auto',
    'BEAUTY': 'Beauty',
    'APPAREL': 'Apparel',
    'EDU': 'Education',
    'ENTERTAIN': 'Entertainment',
    'EVENT_PLAN': 'Event Planning',
    'FINANCE': 'Finance',
    'GROCERY': 'Grocery',
    'GOVT': 'Government',
    'HOTEL': 'Hotel',
    'HEALTH': 'Health',
    'NONPROFIT': 'Nonprofit',
    'PROF_SERVICES': 'Professional Services',
    'RETAIL': 'Retail',
    'TRAVEL': 'Travel',
    'RESTAURANT': 'Restaurant',
    'NOT_A_BIZ': 'Not a Business',
    'ALCOHOL': 'Alcohol',
    'ONLINE_GAMBLING': 'Online Gambling',
    'PHYSICAL_GAMBLING': 'Physical Gambling',
    'OTC_DRUGS': 'OTC Drugs',
}
