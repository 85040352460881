import mitt from 'mitt';
import type {PaddleEventData} from "@paddle/paddle-js";

type Events = {
    'checkout.completed': PaddleEventData
};

// Create a new emitter
const paddleCheckoutEmitter= mitt<Events>();

// Export that emitter
export default paddleCheckoutEmitter;
