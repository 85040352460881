export default {
    "Accounting": "محاسبة",
    "AlternativeDispute": "الحلول البديلة لفض المنازعات",
    "AlternativeMedicine": "الطب البديل",
    "Animation": "الرسوم المتحركة",
    "Apparel": "ثياب",
    "Architecture": "بنيان",
    "Arts": "الفنون",
    "Automotive": "السيارات",
    "Aviation": "طيران",
    "Banking": "الخدمات المصرفية",
    "Biotechnology": "التكنولوجيا الحيوية",
    "Broadcast": "بث وسائل الإعلام",
    "Building": "مواد بناء",
    "Business": "لوازم الأعمال",
    "Capital": "أسواق المال",
    "Chemicals": "مواد كيميائية",
    "Civic": "المنظمات المدنية والاجتماعية",
    "Civil": "هندسة مدنية",
    "Commercial": "العقارات التجارية",
    "Computer": "حاسوب",
    "ComputerGames": "العاب كمبيوتر",
    "ComputerHardware": "أجزاء الكمبيوتر",
    "ComputerNetworking": "شبكات الكمبيوتر",
    "ComputerSoftware": "برامج الكمبيوتر",
    "Construction": "بناء",
    "Consumer": "مستهلكى الكترونيات",
    "ConsumerGoods": "بضائع المستهلكين",
    "ConsumerServices": "خدمات المستهلك",
    "Cosmetics": "مستحضرات التجميل",
    "Dairy": "ألبان",
    "Defense": "دفاع",
    "Design": "تصميم",
    "Education": "التعليم",
    "E": "التعلم الإلكتروني",
    "Electrical": "الكهرباء",
    "Entertainment": "ترفيه",
    "Environmental": "الخدمات البيئية",
    "Events": "خدمات المناسبات",
    "Executive": "المكتب التنفيذي",
    "Farming": "الزراعة",
    "Financial": "الخدمات المالية",
    "Fishery": "مصايد الأسماك",
    "FoodProduction": "إنتاج الغذاء",
    "Fundraising": "جمع التبرعات",
    "Furniture": "أثاث",
    "Gambling": "القمار",
    "Glass": "زجاج وسيراميك",
    "Government": "الإدارة الحكومية",
    "GovernmentRelations": "علاقات حكومية",
    "Graphic": "التصميم الجرافيكي",
    "Health": "الصحة",
    "Higher": "تعليم عالى",
    "Hospital": "مستشفى",
    "Human": "الموارد البشرية",
    "Import": "إستيراد وتصدير",
    "Individual": "فردي",
    "Industrial": "الأتمتة الصناعية",
    "Information": "خدمات المعلومات",
    "InformationTechnology": "تكنولوجيا المعلومات",
    "Insurance": "تأمين",
    "International": "الشؤون الدولية",
    "InternationalTrade": "التجارة العالمية",
    "InvestmentBanking": "الاستثمار المصرفي / المشروع",
    "InvestmentManagement": "إدارة الاستثمار",
    "Judiciary": "القضاء",
    "Law": "تطبيق القانون",
    "LawPractice": "تدريب قانونى",
    "Legal": "خدمات قانونية",
    "Legislative": "المكتب التشريعي",
    "Libraries": "مكتبات",
    "Logistics": "الخدمات اللوجستية",
    "Luxury": "اغراض فخمة",
    "Machinery": "الات",
    "Management": "الاستشارات الإدارية",
    "Maritime": "البحرية",
    "Marketing": "تسويق",
    "Market": "البحث عن المتجر",
    "Mechanical": "هندسة ميكانيكية أو صناعية",
    "Media": "الإنتاج الإعلامي",
    "MedicalDevice": "جهاز طبي",
    "MedicalPractice": "الممارسة الطبية",
    "Mental": "الرعاية الصحية النفسية",
    "Military": "جيش",
    "Mining": "التعدين",
    "Motion": "الصور المتحركة",
    "Museums": "المتاحف",
    "Music": "موسيقى",
    "Nanotechnology": "تقنية النانو",
    "Newspapers": "الصحف",
    "Nonprofit": "إدارة المنظمات غير الربحية",
    "Oil": "زيت",
    "Online": "النشر عبر الإنترنت",
    "Outsourcing": "الاستعانة بمصادر خارجية / الاستعانة بمصادر خارجية",
    "Package": "تسليم الطرود / الشحنات",
    "Packaging": "التعبئة والتغليف",
    "Paper": "ورق",
    "Performing": "الفنون التمثيلية",
    "Pharmaceuticals": "الأدوية",
    "Philanthropy": "الإحسان",
    "Photography": "التصوير",
    "Plastics": "بلاستيك",
    "Political": "التنظيم السياسي",
    "Primary": "ابتدائي / ثانوي",
    "Printing": "طباعة",
    "Professional": "تدريب احترافي",
    "Program": "تطوير البرنامج",
    "PublicPolicy": "سياسة عامة",
    "PublicRelations": "العلاقات العامة",
    "PublicSafety": "السلامة العامة",
    "Publishing": "نشر",
    "Railroad": "تصنيع السكك الحديدية",
    "Ranching": "تربية المواشي",
    "Real": "العقارات",
    "Recreational": "الأنشطة ترفيهية",
    "Facilities": "مرافق",
    "Religious": "المؤسسات الدينية",
    "Renewables": "مصادر متجددة",
    "Research": "أحباث",
    "Restaurants": "مطاعم",
    "Retail": "بيع بالتجزئة",
    "Security": "حماية",
    "Semiconductors": "أشباه الموصلات",
    "Shipbuilding": "بناء السفن",
    "Sporting": "بضائع رياضيه",
    "Sports": "رياضات",
    "Staffing": "التوظيف",
    "Supermarkets": "سوبر ماركت",
    "Telecommunications": "الإتصالات",
    "Textiles": "المنسوجات",
    "Think": "مراكز البحوث",
    "Tobacco": "تبغ",
    "Translation": "ترجمة",
    "Transportation": "النقل / النقل بالشاحنات / السكك الحديدية",
    "Utilities": "خدمات",
    "Venture": "مضاربة",
    "Veterinary": "طبيب بيطري - بيطري",
    "Warehousing": "التخزين",
    "Wholesale": "بيع بالجملة",
    "Wine": "خمور",
    "Writing": "الكتابة"
}
