import {defineStore} from "pinia";
import type {Plan} from "@js/models/Account";
import {useUser} from "@js/stores/User";

export const usePlanStore = defineStore('plan', {
    state: () => ({
        plan: undefined as Plan | undefined
    }),
    actions: {
        show()
        {
            this.plan=useUser().auth.account?.plan
        },
        remove() {
            this.plan = undefined;
        },
    }
})

