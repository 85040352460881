export default {
    'operationSuccess': 'تمت العملية بنجاح',
    'tooManyAttempts': 'محاولات كثيرة. يرجى الانتظار والمحاولة مرة أخرى في وقت لاحق.',
    'cantBeDisplayed': 'لا يمكن عرض هذا العنصر.',
    'cantBeDeleted':'لا يمكن حذف العنصر. تأكد من أن العنصر ليس مُستخدم.',
    'page_expired' : 'الصفحة غير صالحة',
    'emailSentSuccessfully': 'تم إرسال البريد الإلكتروني بنجاح',
    'unauthorized':'هذا الإجراء غير مصرح به',
    'error_on_the_previous_pages':"قد يكون هناك خطأ في الصفحات السابقة.",
    "please_login_for_account_invitation": "الرجاء تسجيل الدخول في الحساب المرسل له الدعوة",
    'update_template_limit':'تنبيه, يمكن تعديل القالب مرة واحدة في اليوم، مع حد أقصى 10 مرات في الشهر عندما يكون الحالة موافق عليه. ومع ذلك، يمكن تعديل القالب عدد غير محدود من المرات عندما تكون الحالة مرفوضة أو متوقف مؤقت',
    'can_edit_template':'يمكن تعديل القالب فقط عندما تكون الحالة موافق عليه، مرفوضة، أو متوقفة',
    "invoice_not_found": "لا يمكن تنزيل هذه الفاتورة، يرجى الاتصال بالدعم لتنزيلها.",
    'request_entity_too_large':'الملف المطلوب كبير جدًا. الحجم الأقصى: 50 ميجابايت',
    'support_success':'لقد تلقينا رسالتك، وسوف نقوم بالرد قريبا',
    'browser_permission_issue': "لا يمكن تنفيذ العملية، يرجى التحقق من صلاحيات المتصفح.",
}
