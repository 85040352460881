import type {RouteRecordRaw} from "vue-router";
import {PermissionsEnum} from "@js/types/generated";
import {checkTeamPermissions} from "@js/routes/RouteGuards";

export default [
    {
        path: '',
        name: 'account',
        component: () => import('@pages/accounts/Account.vue'),
    },
    {
        path: 'conversations',
        children: [
            {
                path: 'inbox',
                component: () => import('@pages/conversations/inbox.vue'),
                name: 'conversations.inbox',
                // meta: {permissions:[PermissionsEnum.IndexMessageable]}
            },
            {
                beforeEnter:checkTeamPermissions,
                path: 'predefined-chat',
                component: () => import('@pages/conversations/PredefinedChat.vue'),
                name: 'conversations.predefinedChat',
                meta: {permissions:[PermissionsEnum.IndexPredefinedResponse]}
            },
            {
                beforeEnter:checkTeamPermissions,
                path: 'broadcasts',
                children: [
                    {
                        path: '',
                        component: () => import('@pages/conversations/broadcast/Broadcast.vue'),
                        name: 'broadcasts.index',
                        meta: {permissions:[PermissionsEnum.IndexBroadcast]}
                    },
                    {
                        path: 'create',
                        component: () => import('@pages/conversations/broadcast/BroadcastCreate.vue'),
                        name: 'broadcasts.create',
                        meta: {permissions:[PermissionsEnum.CreateBroadcast]}
                    },
                    {
                        path: ':id/send',
                        props: true,
                        component: () => import('@pages/conversations/broadcast/BroadcastSend.vue'),
                        name: 'broadcasts.send',
                        meta: {permissions:[PermissionsEnum.SendBroadcast]}
                    },
                    {
                        path: ':id',
                        props: true,
                        component: () => import('@pages/conversations/broadcast/BroadcastUpdate.vue'),
                        name: 'broadcasts.update',
                        meta: {permissions:[PermissionsEnum.UpdateBroadcast]}
                    },
                ],
            },
        ]
    },
    {
        path: 'settings',
        name: 'settings',
        redirect: {name: 'settings.preferences.general'},
        component: () => import('@pages/settings/layouts/SettingsLayout.vue'),
        children: [
            {
                path: 'preferences',
                children: [
                    {
                        path: 'general',
                        name: 'settings.preferences.general',
                        component: () => import('@pages/settings/preferences/General.vue')
                    },
                    {
                        path: 'notifications',
                        name: 'settings.preferences.notifications',
                        component: () => import('@pages/settings/preferences/NotificationsSettings.vue')
                    },
                    {
                        path: 'security',
                        name: 'settings.preferences.security',
                        component: () => import('@pages/settings/preferences/Security.vue')
                    },

                ],
            },
            {
                path: 'accounts',
                children: [
                    {
                        beforeEnter:checkTeamPermissions,
                        path: 'general',
                        name: 'settings.accounts.general',
                        component: () => import('@pages/settings/accounts/General.vue'),
                        meta: {permissions:[PermissionsEnum.SettingAccount]}
                    },
                    {
                        beforeEnter:checkTeamPermissions,
                        path: 'billing',
                        name: 'settings.accounts.billing',
                        component: () => import('@pages/settings/accounts/Billing.vue'),
                        meta: {permissions:[PermissionsEnum.AccountBilling]}
                    },
                ],
            },
            {
                beforeEnter:checkTeamPermissions,
                path: 'platforms',
                children: [
                    {
                        path: 'conversations',
                        name: 'settings.platforms.conversations',
                        component: () => import('@pages/settings/platforms/Conversations.vue'),
                        meta: {permissions:[PermissionsEnum.IndexPlatform]}
                    }
                ]
            },

            {
                beforeEnter:checkTeamPermissions,
                path: 'integrations',
                children: [
                    {
                        path: 'api',
                        name: 'settings.integrations.api',
                        component: () => import('@pages/settings/integrations/API.vue'),
                        meta: {permissions:[PermissionsEnum.IndexPlatform]}
                    },
                    {
                        path: 'webhooks',
                        name: 'settings.integrations.webhooks',
                        component: () => import('@pages/settings/integrations/webhook/Webhook.vue'),
                        meta: {permissions:[PermissionsEnum.IndexWebhook]}

                    }
                ]
            },
            {

                path: 'properties',
                children: [
                    {
                        path:'contacts',
                        redirect: () => ({name: 'settings.properties.contacts'}),
                        children:[
                            {
                                path: '',
                                name: 'settings.properties.contacts',
                                component: () => import('@pages/settings/properties/contacts/ContactsProperties.vue'),

                            },
                            {
                                beforeEnter:checkTeamPermissions,
                                path: 'groups',
                                name: 'settings.properties.contacts.groups',
                                component: () => import('@pages/settings/properties/contacts/ContactsPropertiesGroups.vue'),
                                meta: {permissions:[PermissionsEnum.IndexPropertyGroup]}
                            }
                        ]
                    },
                    {
                        path:'deals',
                        redirect: () => ({name: 'settings.properties.deals'}),
                        children:[
                            {
                                path: '',
                                name: 'settings.properties.deals',
                                component: () => import('@pages/settings/properties/deals/DealsProperties.vue'),
                            },
                            {
                                beforeEnter:checkTeamPermissions,
                                path: 'groups',
                                name: 'settings.properties.deals.groups',
                                component: () => import('@pages/settings/properties/deals/DealsPropertiesGroups.vue'),
                                meta: {permissions:[PermissionsEnum.IndexPropertyGroup]}
                            }
                        ]
                    },
                    {
                        path: 'tickets',
                        redirect: () => ({name: 'settings.properties.tickets'}),
                        children: [
                            {
                                path: '',
                                name: 'settings.properties.tickets',
                                component: () => import('@pages/settings/properties/tickets/TicketProperties.vue'),
                            },
                            {
                                beforeEnter:checkTeamPermissions,
                                path: 'groups',
                                name: 'settings.properties.tickets.groups',
                                component: () => import('@pages/settings/properties/tickets/TicketPropertiesGroups.vue'),
                                meta: {permissions:[PermissionsEnum.IndexPropertyGroup]}
                            }
                        ]
                    }
                ]
            },
            {
                beforeEnter:checkTeamPermissions,
                path: 'users',
                children: [
                    {
                        path: 'teams',
                        name: 'settings.users.teams',
                        component: () => import('@pages/settings/users/Teams.vue'),
                        meta: {permissions:[PermissionsEnum.IndexTeam]}
                    },
                    {
                        beforeEnter:checkTeamPermissions,
                        path: 'users',
                        name: 'settings.users.users',
                        component: () => import('@pages/settings/users/Users.vue'),
                        meta: {permissions:[PermissionsEnum.IndexUser]}
                    },
                ]
            },

        ]
    },
    {
        beforeEnter:checkTeamPermissions,
        path: 'contacts',
        children: [
            {
                path: '',
                name: 'contacts',
                component: () => import('@js/pages/contacts/Contacts.vue'),
                meta: {permissions: [PermissionsEnum.IndexContact, PermissionsEnum.TeamContact, PermissionsEnum.OwnerContact]}
            },
            {
                path: ':id',
                props: true,
                name: 'contact',
                component: () => import('@js/pages/contacts/Contact.vue'),
                meta: {permissions:[PermissionsEnum.ShowContact,PermissionsEnum.TeamContact,PermissionsEnum.OwnerContact]}

            }
        ]
    },
    {
        beforeEnter:checkTeamPermissions,
        path: 'deals',
        children: [
            {
                path: '',
                name: 'deals',
                component: () => import('@js/pages/deals/Deals.vue'),
                meta: {permissions: [PermissionsEnum.IndexDeal, PermissionsEnum.TeamDeal, PermissionsEnum.OwnerDeal]}
            },
            {
                path: ':id',
                props: true,
                name: 'deal',
                component: () => import('@js/pages/deals/Deal.vue'),
                meta: {permissions:[PermissionsEnum.ShowDeal,PermissionsEnum.TeamDeal,PermissionsEnum.OwnerDeal]}
            }
        ]
    },
    {
        beforeEnter:checkTeamPermissions,
        path: 'tickets',
        children: [
            {
                path: '',
                name: 'tickets',
                component: () => import('@js/pages/tickets/Tickets.vue'),
                meta: {permissions: [PermissionsEnum.IndexTicket, PermissionsEnum.TeamTicket, PermissionsEnum.OwnerTicket]}
            },
            {
                path: ':id',
                props: true,
                name: 'ticket',
                component: () => import('@js/pages/tickets/Ticket.vue'),
                meta: {permissions:[PermissionsEnum.ShowTicket,PermissionsEnum.TeamTicket,PermissionsEnum.OwnerTicket]}
            }
        ]
    },
    {
        beforeEnter:checkTeamPermissions,
        path: 'workflows',
        children: [
            {
                path: '',
                name: 'workflows.index',
                component: () => import('@js/pages/workflows/Workflows.vue'),
                meta: {
                    permissions: PermissionsEnum.IndexWorkflow
                }
            },
            {
                path: 'create',
                name: 'workflows.create',
                component: () => import('@js/pages/workflows/Workflow.vue'),
                meta: {
                    permissions: PermissionsEnum.CreateWorkflow
                }
            },
            {
                path: ':id',
                name: 'workflows.update',
                component: () => import('@js/pages/workflows/Workflow.vue'),
                props:true,
                meta: {
                    permissions: PermissionsEnum.UpdateWorkflow
                }
            },
        ]
    }
] as  RouteRecordRaw[]
