export default {
    'property': 'الخاصية',
    'properties': 'الخصائص',
    'properties_desc': 'تُستخدم الخصائص لجمع وتخزين معلومات الكينونات لكي تتمكن من معالجتها أو استيرادها لاحقًا.',
    'new_property': 'خاصية جديدة',
    'group_name': 'إسم المجموعة',
    'name_team':'اسم الفريق',
    'string': 'نص من سطر واحد',
    'text': 'نص من عدة أسطر',
    'number':'رقم',
    'single_select': 'اختيار واحد',
    'date': 'تاريخ',
    'currency': 'عُملة',
    'image': 'صورة',
    'user': 'مُستخدم',
    'team': 'فريق',
    "location": "الموقع",
    "media": "وسائط",
    "video": "فيديو",
    "document": "مستند",

    "exists": "موجود",
    "doesntExist": "غير موجود",
    "equal": "مساوي",
    "notEqual": "غير مساوي",
    "in": "في",
    "notIn": "ليس في",
    "greater": "أكبر",
    "greaterOrEqual": "أكبر أو مساوي",
    "less": "أقل",
    "lessOrEqual": "أقل أو مساوي",

    'selected_columns': 'الأعمدة المختارة',

    'global_property_group':'مجموعة خصائص عامة',
    'alert':'تنبيه',
    'internal_name_alert':'سيتم استخدام هذا المعرف الفريد في واجهات التطبيق البرمجية (API) بمجرد إنشائه لا يمكن تعديله.',
    'update_property':'تعديل الخاصية',
    'date_format': 'صيغة التاريخ',
    'time':'وقت',
    'dateTime':'التاريخ والوقت',
    'options':'الخيارات',
    'option':'الخيار {{index}}',
    'option_language':'الخيار باللغة ',
    'add_new_option':'إضافة خيار جديد',
    "change_language_for_input_name_option": "لغة الخيار",
    'phone_number':'رقم الهاتف'
}
