export default {
    'newContact': 'New Contact',
    'contact': 'Contact',
    'contact_details': 'Contact Details',

    'all_stages': 'All STAGES',

    'activity_created': 'Object has been created.',
    'activity_updated': 'updated',

    'export_view':'Export View',
    'send_to':'The exported file will be sent to {{email}}.',
    'type_file':'Type file',
    'contacts_view':'Contacts View',
    'new_view':'New View',
    'update_view':'Update View',
    'private':'Private',
    'public':'Public',
    'visibility':'Visibility',
    'is_default':'Is Default',
    'alert_properties_col':'Alert Export Selected column the count is',
    'import_view':"Import View",
    'send_notification':'send_notification',
    'upload_file':'Upload File Here',
    'choose_import':'Choose how to import Contacts',
    'create_only':'Create Only',
    'update_create':'Update Or Create',
    'headings':'Headings',
    'unique_keys':" Choose The Unique Keys",
    'mapping_guide':'Mapping Guide',
    'import_send_to':'We will notify you when the import process is done',
    'new_note':'New Note',
    'drag_and_drop_import_file': 'Drag and drop a file here, or click to select one.',
    'allowed_extensions_are': 'The allowed extensions are',
    'main_view':'Main view',
    'save_contact': 'Save contact',
    'add_property':'Add Property',
    'property_error_message': 'Error: {{propertyName}} already exists.',
    'updated_from':'updated from',
    'to':'to',
    'send_template_to':'Send template to {{phone}}',
    'send_broadcast_template_via_whatsapp':'Send broadcast template via WhatsApp',

    'new_deal': 'New Deal',
    'deal_stage' : 'Deal stage',
    'appointment_scheduled' : 'Appointment Scheduled',
    'qualified_to_buy' : 'Qualified to Buy',
    'presentation_scheduled' : 'Presentation Scheduled',
    'decision_maker_bought_in' : 'Decision Maker Bought In',
    'contract_sent' : 'Contract Sent',
    'close_won' : 'Close Won',
    'closed_lost' : 'Closed Lost',
    'contacts_deal':'Deal\'s contacts',
    'contacts_search':'contacts search',
    'amount':'Amount',
    'deal_name':'Deal name',
    'link_contacts':'link contacts',
    'link_deals':'Link deals',
    'deals_contact':'Deals Contact',
    'deals_search':'deals search',

    'contacts_ticket':'Ticket\'s contacts',
    'deals_ticket':'Ticket\'s deals',
    'new_ticket':'New Ticket',
    'all_categories':'All Categories',
    'category' : 'Category',
    'product_issue' : 'Product Issue',
    'billing_issue' : 'Billing Issue',
    'feature_request' : 'Feature Request',
    'general_inquiry' : 'General Inquiry',

    'tickets_deal':'Deal\'s tickets',
    'tickets_search':'tickets search',
    'link_tickets':'Link tickets',
    'tickets_contact': 'Tickets Contact',

    'source_manual':'Manual',
    'source_conversation':'Conversation',
    'go_to_contact': 'Go to contact',

    'merge_contacts':'Merge Contacts',
    'merge_contacts_tooltip': 'Merge current contacts into another contact',
    'merge_contacts_description': 'The current contact will be merged into the selected contact.' +
        ' The current contact will be deleted and all associated data will be moved. Are you sure you want to continue?',
    'select_contact_to_merge': 'Select the contact to merge',
    'channels': 'Channels',

    'contacts_merged': 'Contacts merged successfully',
}
