export default {
    IncomingMessage: 'رسالة واردة',
    ObjectsExport:'تصدير الكائنات',
    ObjectsImport:'استيراد الكائنات',
    WhatsAppTemplateChangeStatus:'تغيير حالة قالب النشر',
    WhatsAppChangeTemplateCategory:'تعير فئة قالب النشر',
    WhatsappBroadcastCompleted:'الإنتهاء من نشر الواتس اب',
    'read_as_mark':'تحديد كمقروء',
    'un_read_mark':'تحديد كغير مقروء',
    'un_read_notifications':'الإشعارات غير المقروءة',
    'all_notifications':'جميع الإشعارات',
    'export_contacts_completed':'تم إكمال التصدير العملاء.',
    'import_contacts_completed':'تم إكمال استيراد العملاء.',
    'whatsapp_template_Approved':'تم الموافقة على القالب',
    'whatsapp_template_Rejected':'تم رفض القالب',
    'whatsapp_template_Pending' : 'القالب مُعلق',
    'whatsapp_template_InAppeal':'القالب قيد الاستئناف',

    'whatsapp_template_Paused':'القالب متوقف مؤقتاً',

    'whatsapp_template_category_changed_to_utility' : 'تم تغيير فئة قالب النشر إلى مَنفعي',
    'whatsapp_template_category_changed_to_marketing' : 'تم تغيير فئة قالب النشر إلى تسويقي',
    'whatsapp_template_category_changed_to_authentication' : 'تم تغيير فئة قالب النشر إلى مصادقة',

}
