import type { Paddle as PaddleInstance} from "@paddle/paddle-js";
import {initializePaddle} from "@paddle/paddle-js";
import {useUser} from "@js/stores/User";
import type {Plan} from "@js/models/Account";
import {i18n} from "@lang/t";
import paddleCheckoutEmitter from "@js/models/Paddle/PaddleCheckoutEvents";

export type PaddleIntervals = 'YEARLY'|'MONTHLY';
export type Purpose = 'UPGRADE'|'CREATE';

class Paddle
{
    public instance: PaddleInstance
    public STARTER_MONTHLY = import.meta.env.VITE_STARTER_PLAN_MONTHLY_PRICE_ID
    public STARTER_YEARLY = import.meta.env.VITE_STARTER_PLAN_YEARLY_PRICE_ID
    public BUSINESS_MONTHLY = import.meta.env.VITE_BUSINESS_PLAN_MONTHLY_PRICE_ID
    public BUSINESS_YEARLY = import.meta.env.VITE_BUSINESS_PLAN_YEARLY_PRICE_ID

    init()
    {
        initializePaddle({
            // environment: import.meta.env.DEV ? "sandbox" : "production",
            environment: import.meta.env.VITE_PADDLE_SANDBOX ? "sandbox" : "production",
            token: import.meta.env.VITE_PADDLE_CLIENT_SIDE_TOKEN,
            pwCustomer: {
                email: useUser().user?.email
            },
            eventCallback: (event) => {
                if(event.name === 'checkout.completed') {
                    paddleCheckoutEmitter.emit('checkout.completed', event)
                }
            }
        }).then((paddleInstance: PaddleInstance | undefined) => {
            if(paddleInstance) {
                this.instance = paddleInstance
                return;
            }
            new Error("Couldn't init paddle")
        })
    }

    checkoutTransaction(transactionId: string)
    {
        this.instance.Checkout.open({
            transactionId: transactionId,
            settings: {
                theme: 'dark',
                locale: i18n.language
            },
        })
    }

    checkout(plan: Plan, interval: PaddleIntervals = 'YEARLY', data = {})
    {
        if(plan === 'free') {
            throw new Error('No checkout for free plan')
        }

        let prices = {
            'starter': {
                'YEARLY': this.STARTER_YEARLY,
                'MONTHLY': this.STARTER_MONTHLY,
            },
            'business': {
                'YEARLY': this.BUSINESS_YEARLY,
                'MONTHLY': this.BUSINESS_MONTHLY,
            }
        }

        let priceId = prices[plan][interval];

        this.instance.Checkout.open({
            items: [{
                quantity: 1,
                priceId: priceId
            }],
            customData: {
                ...data
            },
            discountId: import.meta.env.VITE_AUTOMATIC_DISCOUNT_ID,
            customer: {
                email: useUser().auth.email
            },
            settings: {
                theme: 'dark',
                locale: i18n.language
            },
        })
    }
}

export default new Paddle()
