import type {ID} from "@js/APIs/api";

export const teamSuperAdmin:string = 'super admin'
export class Team
{
    constructor(data: Team) {
        this.id = data.id
        this.name= data.name
        this.description= data.description
        this.permissions= data.permissions
        this.users_count= data.users_count
        this.made_by= data.made_by
        this.created_at= data.created_at
    }

    public id: ID
    public name: string
    public description: string
    public permissions: Permission[]
    public users_count:number
    public made_by:ID|null
    public created_at:string
}

export type TeamIdAndName = Pick<Team, 'id' | 'name'>
export type Permission ={id:ID,name:string}
