<template>
    <v-dropdown>
        <div class="button cursor-pointer" v-bind="$attrs">
            {{ !!modelValue ? modelValue.label : placeholder }}
            <icon-btn icon="mdi:menu-down" class="text-xl"/>
        </div>

        <template #popper>
            <div class="flex flex-col gap-2 options">
                <button v-if="placeholder && !required" v-close-popper :class="{active:!modelValue}" @click="select(undefined)">
                    {{ placeholder }}
                </button>

                <button v-for="(option, index) in options" :key="option.value ?? index" v-close-popper
                        :class="{active:option.value === modelValue?.value}" @click="select(option)">
                    {{ option.label }}
                </button>

                <div v-if="hasPages" class="flex justify-around">
                    <icon-btn icon="mdi:chevron-right"/>
                    <icon-btn icon="mdi:chevron-left"/>
                </div>
            </div>
        </template>
    </v-dropdown>
</template>

<script setup lang="ts">
import type {Option} from "@components/forms/MultiselectTypes";

defineOptions({
    inheritAttrs: false
})

withDefaults(defineProps<{
    modelValue: Option|undefined,
    options: Option[]
    placeholder?: string,
    hasPages?: boolean,
    required?: boolean
}>(), {
    hasPages: false,
    required: false
})

const emits = defineEmits(['update:modelValue'])

function select(option: Option | undefined)
{
    emits('update:modelValue', option)

}
</script>

<style scoped lang="scss">
.options {
    > button {

        @apply py-2 px-3;

        &.active {
            @apply bg-primary text-white;
        }

        &:hover:not(.active) {
            @apply bg-gray-100;
        }
    }
}
</style>
