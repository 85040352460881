class SW
{
    register() {
        return navigator.serviceWorker
            .register('/sw.js')
            .then(function (registration) {
                return registration;
            })
            .catch(function (err) {
                console.error('Unable to register service worker.', err);
            });
    }

    isSupported() {
        return 'serviceWorker' in navigator;
    }
}

export default new SW()
