<template>
    <div v-if="isLoading" class="inline-block" v-bind="$attrs">
        <Icon icon="mdi:loading" class="animate-spin"/>
    </div>
    <slot v-else />
</template>

<script lang="ts">
// use normal <script> to declare options
export default {
    inheritAttrs: false
}
</script>

<script setup lang="ts">
import {Icon} from "@iconify/vue";

defineProps({
    isLoading: {
        type: Boolean,
        required: true
    }
})
</script>

<style scoped lang="scss"></style>
