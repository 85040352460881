import {defineStore} from "pinia";
import type {ID} from "@js/APIs/api";
import api from "@js/APIs/api";
import type {Account} from "@js/models/Account";
import type {AxiosResponse} from "axios";
import urlResolver from "@js/routes/UrlResolver";
import type {UsersInvitation} from "@js/models/users/UsersInvitation";
import type {Team} from "@js/models/users/Team";
import notifications from "@js/PopupNotifications";
import type {NotificationPreference} from "@js/models/users/Notification";
import {LocaleEnum} from "@js/types/generated";
import router from "@js/routes/router";
import {useLocalStorage} from "@vueuse/core";
import {teamSuperAdmin} from "@js/models/users/Team";
import {accountRoute} from "@js/routes/RouteGuards";

export class User {
    public id: number | string
    public firstname: string
    public lastname: string
    public fullname: string
    public email: string
    public token: string
    public image: string
    public email_verified_at?: string
    public status: string
    public contacts_view_id?: ID | null
    public deals_view_id?: ID | null
    public tickets_view_id?: ID | null

    public has2FA: boolean
    public unread_notifications_count: number

    // UserPreferences
    public timeZone: string
    public calendar: string
    public hour12: boolean
    public locale: string
    public currency: string
    public numberingSystem: string

    public account?: Account
    public teams?: Team[]
    // the user may not have an invitation, he may be the root user.
    public invitation?: UsersInvitation
    public notification_preferences?: NotificationPreference[]

    constructor(object: User) {
        Object.assign(this, object)

        let options = Intl.DateTimeFormat().resolvedOptions();
        const matchedLocale = options.locale.startsWith('ar') ? 'ar' : 'en-GB';
        this.currency = this.currency ?? this.account?.currency?? 'USD'
        this.locale = this.account?.pivot?.locale ?? this.account?.locale ??
            Object.values(LocaleEnum).find(value => value === options.locale) ?? matchedLocale
        this.hour12 = this.account?.pivot?.hour12 ?? this.account?.hour12 ?? (options.hour12 ?? true)
        this.calendar = this.account?.pivot?.calendar?? this.account?.calendar ?? options.calendar
        this.timeZone = this.account?.pivot?.timeZone ??this.account?.timeZone ?? options.timeZone
        this.numberingSystem = this.numberingSystem ?? options.numberingSystem
    }
}

export const useUser = defineStore('user', {
    state: () => ({
        user: null as User | null,
        // couldn't get the accounts from the user because we need to order them by the last_used account
        latest_accounts: useLocalStorage('latestAccounts', [] as Account[]),
        // hasNotificationSubscription is not used anymore, but keeping it for the record
        hasNotificationSubscription: useLocalStorage('hasNotificationSubscription', false)
    }),
    getters: {
        hasReachedMaxLocalAccounts(state) {
            return state.latest_accounts.length >= 5
        },
        auth(state): User {
            if (!state.user) {
                router.push({name: 'login'})
                throw new Error('Unauthenticated')
            }

            return state.user
        },
        authAccount(): Account {
            if (!this.auth.account) {
                router.push(accountRoute())
                throw new Error('Unauthenticated')
            }

            return this.auth.account
        },
        getPermissions() {
            let teams = this.user?.teams
            const uniquePermissions: Set<string> = new Set();
            teams?.forEach(team => {
                team.permissions.forEach(permission => {
                        uniquePermissions.add(permission.name)
                    })
                })
            return uniquePermissions;
        },
        isSuperAdmin(): boolean {
            let teams = this.auth.teams
            return teams?.some(team => team.name === teamSuperAdmin) ?? false;
        },
        getUnreadNotificationsCount():number{
            return this.user?.unread_notifications_count??0;
        },
        isSubPeriodLessThanYear(): boolean
        {
            let account = this.authAccount;
            return !!account.subscription && account.subscription.interval < 365;
        }
    },
    actions: {
        can(...targetPermissions:string[]) {
            if (this.auth.teams) {
                let userPermissions= this.getPermissions
                return this.isSuperAdmin ||  targetPermissions.some(permission => userPermissions.has(permission));
            }

            return false;
        },
        addAccountsToLocalStorage(accounts: Account[]) {
            accounts.forEach((dbAccount) => {
                if (this.hasReachedMaxLocalAccounts) return

                const exist = this.latest_accounts.find((localAccount) => localAccount.id === dbAccount.id)
                if (typeof exist === 'undefined') {
                    this.latest_accounts.push(dbAccount)
                }
            })
        },
        setUser(user: User) {
            this.user = new User(user)

            if (this.user.account) {
                // remove that account from the array and re-set it at the top of the array
                this.latest_accounts = this.latest_accounts.filter((account) =>
                    account.id !== user.account?.id)

                this.latest_accounts.unshift(this.user.account)
            }

            notifications.init();
        },
        async fetchUser(accountId: ID | null = null) {

            return api.get('/me', {
                headers: {
                    'Account-ID': accountId ?? urlResolver.getUrlAccountId()
                }
            })
                .then((response: AxiosResponse<User>) => {
                    this.setUser(response.data)

                    return response.data
                })
        },
        logout() {
            return api.post('logout').then(() => {
                this.latest_accounts = []
                this.user = null
            })
        }
    }
})
