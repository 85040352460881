export default {
    'search' : 'Search emoji',
    'skinTone' : 'Skin tone',
    "smileys_people": "Smiles & People",
    "animals_nature": "Animals & Nature",
    "food_drink": "Food & Drink",
    "activities": "Activities",
    "travel_places": "Travel places",
    "objects": "Objects",
    "symbols": "Symbols",
    "flags": "Flags",
    "recent": 'Recently used'
}
